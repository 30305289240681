import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperWhatShouldTeenagerDo'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextStepper = makeShortcode("TextStepper");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-should-my-teenager-be-doing"
    }}>{`What should my teenager be doing?`}</h1>
    <p>{`The amount of responsibility and independence you give your teenager should be based on their age and level of maturity. As a guide, from the age of 11 years, teenagers should be capable of taking care of their own belongings, putting dirty clothes in the laundry basket, and packing away their things every night. Depending on their maturity, they can:`}</p>
    <TextStepper id="textStepperWhatShouldTeenagerDo" sequenceSpeed={1200} fadeSpeed={1200} list={[{
      text: 'Travel on public transport without a parent.'
    }, {
      text: 'Make and organise their own lunches.'
    }, {
      text: 'Be responsible for taking out the bins.'
    }, {
      text: 'Organise their own finances such as banking and spending money.'
    }, {
      text: 'Plan their own parties with your permission.'
    }, {
      text: 'Take responsibility for buying clothes (within an agreed budget).'
    }, {
      text: 'Help with jobs around the house.'
    }, {
      text: 'Look after the family pet.'
    }, {
      text: 'Vacuum the house.'
    }, {
      text: 'Put younger siblings to bed or read to younger siblings.'
    }, {
      text: 'Cook and prepare an evening meal for the family.'
    }]} mdxType="TextStepper" />
    <p>{`Encouraging your teenager to help around the house builds their sense of responsibility and independence. Knowing how to cook food and wash their clothes are very helpful life skills for when they leave home! But also, becoming more independent in this way helps to build their self-confidence.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      